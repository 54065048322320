// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/icons/Import.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import{font-style:normal;position:relative;padding-left:25px}.import::before{content:url(${___CSS_LOADER_URL_REPLACEMENT_0___});position:absolute;left:-5px}input[type='file']{display:none}.custom-file-upload{border:2px solid #0189e3;border-radius:7px;color:#0189e3;font-weight:600;display:inline-block;font-size:18px;padding:6px 12px;cursor:pointer}.file-item{background-color:rgba(1,137,227,0.21);width:fit-content;border-radius:20px;padding:10px;color:#505050;font-style:italic}.delete-file-item{border:none;background-color:transparent;margin-left:20px;cursor:pointer}.delete-file-item:hover{color:#505050}.save-button:disabled{cursor:not-allowed}
`, "",{"version":3,"sources":["webpack://./src/src/components/Common/Documents/Documents.scss","webpack://./src/src/assets/scss/_color.scss"],"names":[],"mappings":"AAEA,QACE,iBAAkB,CAClB,iBAAkB,CAClB,iBAAkB,CACnB,gBAGC,+CAAgD,CAChD,iBAAkB,CAClB,SAAU,CACX,mBAGC,YAAa,CACd,oBAGC,wBClBiB,CDmBjB,iBAAkB,CAClB,aCpBiB,CDqBjB,eAAgB,CAChB,oBAAqB,CACrB,cAAe,CACf,gBAAiB,CACjB,cAAe,CAChB,WAGC,qCAAyC,CACzC,iBAAkB,CAClB,kBAAmB,CACnB,YAAa,CACb,aC5BY,CD6BZ,iBAAkB,CACnB,kBAGC,WAAY,CACZ,4BAA6B,CAC7B,gBAAiB,CACjB,cAAe,CAChB,wBAGC,aCxCY,CDyCb,sBAGC,kBAAmB","sourcesContent":["@import '../../../assets/scss/_color.scss';\n\n.import {\n  font-style: normal;\n  position: relative;\n  padding-left: 25px;\n}\n\n.import::before {\n  content: url('../../../assets/icons/Import.svg');\n  position: absolute;\n  left: -5px;\n}\n\ninput[type='file'] {\n  display: none;\n}\n\n.custom-file-upload {\n  border: 2px solid $secondary;\n  border-radius: 7px;\n  color: $secondary;\n  font-weight: 600;\n  display: inline-block;\n  font-size: 18px;\n  padding: 6px 12px;\n  cursor: pointer;\n}\n\n.file-item {\n  background-color: rgba(1, 137, 227, 0.21);\n  width: fit-content;\n  border-radius: 20px;\n  padding: 10px;\n  color: $gray;\n  font-style: italic;\n}\n\n.delete-file-item {\n  border: none;\n  background-color: transparent;\n  margin-left: 20px;\n  cursor: pointer;\n}\n\n.delete-file-item:hover {\n  color: $gray;\n}\n\n.save-button:disabled {\n  cursor: not-allowed;\n}\n","$primary: #20458f;\n$secondary: #0189e3;\n$red: #f25524;\n$red1: #ffdcd1;\n$green: #01e37a;\n$green1: #c6ffe4;\n$gray: #505050;\n$gray3: #b7b7b7;\n$blocked_input: #efefef;\n$border_table: #e5e5e5;\n$border_bottom_header_dropdown: #6f98eb;\n$active_menu: #f09637;\n$border_bottom_select_list_item: #d9d9d9;\n$border_header_table: #707070;\n$border_children_cell_table: #707070;\n$border_input: #bebebe;\n$placeholder_input: #808080;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
