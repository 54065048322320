import { Modal, notification } from 'antd'
import { useState } from 'react'
import { FileUpload } from '../../../models'
import './Documents.scss'

interface DocumentsProps {
  /**
   * show document if open
   */
  isOpen: boolean
  /**
   * function pass upload file to parent component
   * @param data list of upload file
   * @returns void
   */
  onConfirm: (data: FileUpload[]) => void
  /**
   * function close modal Document
   * @returns void
   */
  onClose: () => void
}

//limit size each time upload is 20 Mb
const LIMIT_FILE_SIZE = 20000000
//limit number of files each time upload is 10
const LIMIT_FILE_NUMBER = 10

/**
 * The @Documents is a component display a modal to upload file.
 * @author [anhnq]
 * @version 0.1
 */

const Documents = (props: DocumentsProps) => {
  const { isOpen, onConfirm, onClose } = props
  const [listFile, setListFile] = useState<any>([])

  //function call when click button Annuler
  const handleAnnuler = () => {
    setListFile([])
    onClose()
  }

  //function call when upload file
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.currentTarget.files

    if (files) {
      let initialValue = 0

      let mapFiles = Array.from(files).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
      }))

      let mapSize = Array.from(files).map((file) => file.size)
      //caculate size of files uploaded
      let sumFileSize = Array.from(mapSize).reduce(
        (prevValue, currentValue) => prevValue + currentValue,
        initialValue
      )
      //if size of files > 20Mb or number of files > 10: display error notification
      if (
        mapFiles.length > LIMIT_FILE_NUMBER ||
        sumFileSize >= LIMIT_FILE_SIZE
      ) {
        notification.open({
          message:
            'La taille ou le nombre de fichier dépasse la limite maximale',

          placement: 'top',
          className: 'text-sm',
          duration: 3,
        })
        setListFile([])
      } else setListFile(files)
    } else {
      setListFile([])
    }
  }

  //function delete file from UI and list file
  const deleteFileItem = (fileName: string) => {
    let newFileArray = Array.from(listFile).filter(
      (f: any) => f.name !== fileName
    )
    setListFile(newFileArray)
  }

  return (
    <Modal
      open={isOpen}
      width={984}
      onCancel={onClose}
      footer={
        <div className="mt-4">
          <button
            onClick={handleAnnuler}
            data-testid="testAnnuler"
            className="mr-4 border-red border-solid bg-white hover:text-red text-red text-lg font-semibold px-5 py-1 rounded-lg cursor-pointer relative"
          >
            Annuler
          </button>

          <button
            onClick={() => {
              onConfirm(listFile)
              setListFile([])
            }}
            disabled={Array.from(listFile).length < 1}
            className={`${
              listFile.length > 0 ? 'bg-[#01E37A]' : 'bg-gray3'
            } save-button border-none text-white hover:text-white text-lg font-semibold  px-5 py-1 rounded-lg cursor-pointer relative`}
          >
            Sauvegader
          </button>
        </div>
      }
    >
      <div className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
        <span className="modal-title-heading-1 mr-1">Importation</span>
      </div>
      <div>
        <ul className="text-base text-[#505050] my-10">
          Veuillez sélectionner le fichier que vous souhaitez importer:
          <li>Fichiers pris en charge PDF, JPG, PNG</li>
          <li>
            La taille des fichiers peut atteindre 20 Mb, tous les fichiers
            dépassant cette limite seront supprimés
          </li>
          <li>
            Le nombre maximum de fichier pour chaque importation est de 10
          </li>
          <li>
            Les fichiers importés seront supprimés et remplacés par les nouveaux
            fichiers dans la prochaine importation
          </li>
        </ul>

        <label className="custom-file-upload">
          <input
            className="hidden"
            type="file"
            accept=".jpg, .pdf, .png"
            multiple
            onChange={handleChangeFile}
            //clear cache after each time upload file
            onClick={(e) => ((e.target as HTMLInputElement).value = '')}
            data-testid="uploadFileInput"
          />
          <i className="import">Importer</i>
        </label>
        <div
          style={{ overflowY: 'auto', height: 'calc(25vh)' }}
          data-testid="handlerFileUpload"
        >
          {Array.from(listFile).map((file: any, index) => (
            <p key={index} className="file-item">
              {file.name}
              <button
                className="delete-file-item"
                onClick={() => deleteFileItem(file.name)}
              >
                x
              </button>
            </p>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default Documents
